<template>
<div>
    <CCard>
        <CCardHeader color="primario" text-color="white">
            <i class="fas fa-edit fa-md"></i> <span class="h5"> Gestión de objetivos</span>
        </CCardHeader>
        <CCardBody>
            <b-row>
                <b-col md="12">
                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                        <b-form @submit.stop.prevent="handleSubmit(guardarCambios)">
                            <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark">
                                <b-tab title="Datos generales" active>
                                    <b-row>
                                        <b-col md="6">
                                            <validation-provider name="proceso" rules="required" v-slot="{errors}">
                                                <b-form-group label="Proceso:" class="mb-2">
                                                    <v-select :reduce="comboProcesos =>comboProcesos.idProceso" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosObjetivo.proceso , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosObjetivo.proceso" :options="comboProcesos" @search:blur="blurProceso">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Mapa de procesos'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <div class="invalid-feedback">{{ errors[0]}}</div>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <validation-provider name="definición de objetivo" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Definición de objetivo:" class="mb-2">
                                                    <b-form-input type="text" :state="getValidationState(validationContext)" placeholder="Ingrese la definición del objetivo" v-model="datosObjetivo.definicionObjetivo"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="3">
                                            <validation-provider name="fecha inicio" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Fecha inicio:" class="mb-2">
                                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosObjetivo.fechaInicio"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="3">
                                            <validation-provider name="fecha límite" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Fecha límite:" class="mb-2">
                                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosObjetivo.fechaLimite"></b-form-input>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form-group label="Valor real:" class="my-2">
                                                <b-form-radio-group small plain v-model="datosObjetivo.valor" :options="radioValor"></b-form-radio-group>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider id="valor_partida" name="valor de partida" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Valor de partida:" class="mb-2">
                                                    <b-input-group>
                                                        <b-form-input type="number"  step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosObjetivo.valorPartida1"></b-form-input>
                                                        <b-input-group-append>
                                                            <b-input-group-text>%</b-input-group-text>
                                                        </b-input-group-append>
                                                        <template v-if="datosObjetivo.valor==2">
                                                            <span class="slash-space"> / </span>
                                                            <b-form-input type="number"  step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosObjetivo.valorPartida2"></b-form-input>
                                                            <b-input-group-append>
                                                                <b-input-group-text>%</b-input-group-text>
                                                            </b-input-group-append>
                                                        </template>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="condición" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Condición:" class="mb-2">
                                                    <b-form-select v-model="datosObjetivo.condicion" :options="comboCondicion" :state="getValidationState(validationContext)" value-field="idCondicion" text-field="descripcion">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <b-col md="4">
                                            <validation-provider name="valor esperado" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Valor esperado:" class="mb-2">
                                                    <b-input-group>
                                                        <b-form-input type="number"  step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosObjetivo.valorEsperado1"></b-form-input>
                                                        <b-input-group-append>
                                                            <b-input-group-text>%</b-input-group-text>
                                                        </b-input-group-append> <template v-if="datosObjetivo.valor==2">
                                                            <span class="slash-space"> / </span>
                                                            <b-form-input type="number"  step=".01" :state="getValidationState(validationContext)" placeholder="00.00" v-model="datosObjetivo.valorEsperado2"></b-form-input>
                                                            <b-input-group-append>
                                                                <b-input-group-text>%</b-input-group-text>
                                                            </b-input-group-append>
                                                        </template>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <validation-provider name="tolerancia" :rules="{required: true}" v-slot="validationContext">
                                                <b-form-group label="Tolerancia:" class="mb-2">
                                                    <b-input-group>
                                                        <b-form-input type="number"  step=".01" :state="getValidationState(validationContext)" placeholder="Ingrese la tolerancia" v-model="datosObjetivo.tolerancia"></b-form-input>
                                                        <b-input-group-append>
                                                            <b-input-group-text>%</b-input-group-text>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                        <b-col md="4">
                                            <b-form-group label="¿Conseguido?" class="my-2">
                                                <b-form-radio-group small plain v-model="datosObjetivo.conseguido" :options="radioConseguido"></b-form-radio-group>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                                <b-tab title="Metas/Hitos a llevar a cabo para conseguir el objetivo">
                                    <b-row>
                                        <b-col md="12">
                                            <CButton size="sm" color="dark" class="float-right" @click="registrarMetaHito()">
                                                <i class="fas fa-plus fa-sm"></i> Agregar
                                            </CButton>
                                        </b-col>

                                        <b-col md="12 mt-2">
                                            <b-table :items="datosObjetivo.listaMetasHitos.filter(x => x.estado == '2')" :fields="campoMetasHitos" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                <template v-slot:cell(definicion)="row">
                                                    <b-form-input type="text" v-model="row.item.definicion" placeholder="Ingrese definición"></b-form-input>
                                                </template>
                                                <template v-slot:cell(fechaLimite)="row">
                                                    <b-form-input type="date" v-model="row.item.fechaLimite"></b-form-input>
                                                </template>
                                                <template v-slot:cell(idResponsable)="row">
                                                    <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" v-model="row.item.idResponsable" :options="comboCargoResponsable">
                                                        <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                                    </v-select>
                                                    <!-- <validation-provider :name="'empleado ' + row.index" :rules="{required: true}" v-slot="validationContext">
                                                        <b-form-select v-model="row.item.idResponsable" :state="getValidationState(validationContext)" :options="comboEmpleados" value-field="idResponsable" text-field="nombres">
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                                            </template>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-select>
                                                    </validation-provider> -->
                                                    <!-- <b-form-input type="text" v-model="row.item.responsable"></b-form-input> -->
                                                </template>
                                                <template v-slot:cell(recursos)="row">
                                                    <b-form-input type="text" v-model="row.item.recursos" placeholder="Ingrese recursos"></b-form-input>
                                                </template>
                                                <template v-slot:cell(conseguido)="row">
                                                    <b-form-checkbox plain :id="'checkbox-'+row.index" v-model="row.item.conseguido" :name="'checkbox-'+row.index" value="2" unchecked-value="1"></b-form-checkbox>
                                                </template>
                                                <template #cell(acciones)="param">
                                                    <b-button @click="eliminarMetaHito(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                    </b-button>
                                                </template>
                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                                <b-tab title="Seguimiento">
                                    <b-row>
                                        <b-col md="12">
                                            <CButton size="sm" color="dark" class="float-right" @click="registrarSeguimiento()">
                                                <i class="fas fa-plus fa-sm"></i> Agregar
                                            </CButton>
                                        </b-col>
                                        <b-col md="12" class="mt-2">
                                            <b-table :items="datosObjetivo.listaSeguimientos.filter(x => x.estado == 2)" :fields="campoSeguimientos" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                                                <template v-slot:cell(fecha)="row">
                                                    <b-form-input type="date" v-model="row.item.fecha"></b-form-input>
                                                </template>
                                                <template v-slot:cell(valor)="row">
                                                    <b-input-group>
                                                        <b-form-input type="number" placeholder="00.00" v-model="row.item.valorx"></b-form-input>
                                                        <b-input-group-append>
                                                            <b-input-group-text>%</b-input-group-text>
                                                        </b-input-group-append>
                                                        <span class="slash-space"> / </span>
                                                        <b-form-input type="number" placeholder="00.00" v-model="row.item.valory"></b-form-input>
                                                        <b-input-group-append>
                                                            <b-input-group-text>%</b-input-group-text>
                                                        </b-input-group-append>
                                                    </b-input-group>

                                                </template>
                                                <template #cell(acciones)="param">
                                                    <b-button @click="eliminarSeguimiento(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                    </b-button>
                                                </template>

                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </b-tab>
                            </b-tabs>
                            <b-row>
                                <b-col class=" my-2 text-center" md="12">
                                    <b-button :to="{name: 'Objetivos'}" class="mr-2" variant="dark">
                                        <i class="fas fa-arrow-left"></i> Volver
                                    </b-button>
                                    <b-button v-if="$route.params.id && checkPermissions('002-PRO-OBJ','u')==1" size="md" type="submit" variant="success"> <i class="fas fa-save"></i> Guardar</b-button>
                                    <b-button v-if="!$route.params.id && checkPermissions('002-PRO-OBJ','c')==1" size="md" type="submit" variant="success"> <i class="fas fa-save"></i> Guardar</b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-row>

        </CCardBody>
    </CCard>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            comboProcesos: [],
            comboCondicion: [{
                    idCondicion: 1,
                    descripcion: 'IGUAL'
                },
                {
                    idCondicion: 2,
                    descripcion: 'MAYOR O IGUAL'
                },
                {
                    idCondicion: 3,
                    descripcion: 'MAYOR'
                },
                {
                    idCondicion: 4,
                    descripcion: 'MENOR O IGUAL'
                },
                {
                    idCondicion: 5,
                    descripcion: 'MENOR'
                }
            ],
            comboEmpleados: [],

            campoMetasHitos: [{
                    key: "definicion",
                    label: "Definición",
                    class: "text-center",
                },
                {
                    key: "fechaLimite",
                    label: "Fecha límite",
                    class: "text-center",
                },
                {
                    key: "idResponsable",
                    label: "Responsable",
                    class: "text-center",
                },
                {
                    key: "recursos",
                    label: "Recursos",
                    class: "text-center",
                },
                {
                    key: "conseguido",
                    label: "¿Conseguido?",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: '',
                    class: "text-center"
                }
            ],

            radioValor: [{
                    text: 'Estático',
                    value: 1
                },
                {
                    text: 'Fórmula',
                    value: 2
                }
            ],
            radioConseguido: [{
                    text: 'No',
                    value: 1
                },
                {
                    text: 'Si',
                    value: 2
                }
            ],
            comboConseguido: [{
                    value: '2',
                    text: 'Si'
                },
                {
                    value: '1',
                    text: 'No'
                }
            ],
            campoSeguimientos: [{
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "valor",
                    label: "Valor",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: '',
                    class: "text-center"
                }
            ],
            datosObjetivo: {
                idObjetivo: '',
                idCliente: '',
                proceso: null,
                definicionObjetivo: '',
                valor: 1,
                fechaInicio: moment().format('YYYY-MM-DD'),
                fechaLimite: moment().format('YYYY-MM-DD'),
                valorPartida1: '',
                valorPartida2: '',
                condicion: null,
                tolerancia: '',
                valorEsperado1: '',
                valorEsperado2: '',
                conseguido: 1,
                listaMetasHitos: [],
                listaSeguimientos: [],
            },
            comboCargoResponsable: [],
        }
    },
    methods: {

        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        guardarCambios() {
            if (this.$route.params.id) {
                this.actualizarObjetivo();
            } else {
                this.registrarObjetivo();
            }
        },

        registrarMetaHito() {
            let me = this;
            me.datosObjetivo.listaMetasHitos.push({
                definicion: '',
                fechaLimite: moment().format('YYYY-MM-DD'),
                idResponsable: null,
                recursos: '',
                conseguido: 1,
                estado: 2
            })
        },
        registrarSeguimiento() {
            let me = this;
            me.datosObjetivo.listaSeguimientos.push({
                fecha: moment().format('YYYY-MM-DD'),
                valorx: '',
                valory: '',
                estado: 2
            })
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosObjetivo.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.comboCargoResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarObjetivo() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-objetivo",
                    me.datosObjetivo, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.$router.push({
                        name: 'Objetivos'
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        actualizarObjetivo() {
            let me = this;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/actualizar-objetivo",
                    me.datosObjetivo, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.$router.push({
                        name: 'Objetivos'
                    });

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        eliminarMetaHito(param) {
            let me = this;
            if (this.$route.params.id) {
                let listaMetasHitos = me.datosObjetivo.listaMetasHitos.filter(x => x.estado == 2);
                if (listaMetasHitos[param.index].idObjetivo) {
                    listaMetasHitos[param.index].estado = 1
                } else if (!listaMetasHitos[param.index].idObjetivo) {
                    listaMetasHitos[param.index].estado = 0
                    for (let e in me.datosObjetivo.listaMetasHitos) {
                        if (me.datosObjetivo.listaMetasHitos[e].estado == 0) {
                            me.datosObjetivo.listaMetasHitos.splice(e, 1);
                        }
                    }
                }
            } else {
                me.datosObjetivo.listaMetasHitos.splice(param.index, 1)
            }

        },
        eliminarSeguimiento(param) {
            let me = this;
            if (this.$route.params.id) {
                let listaSeguimientos = me.datosObjetivo.listaSeguimientos.filter(x => x.estado == 2);
                if (listaSeguimientos[param.index].idObjetivo) {
                    listaSeguimientos[param.index].estado = 1
                } else if (!listaSeguimientos[param.index].idObjetivo) {
                    listaSeguimientos[param.index].estado = 0
                    for (let e in me.datosObjetivo.listaSeguimientos) {
                        if (me.datosObjetivo.listaSeguimientos[e].estado == 0) {
                            me.datosObjetivo.listaSeguimientos.splice(e, 1);
                        }
                    }
                }
            } else {
                me.datosObjetivo.listaSeguimientos.splice(param.index, 1)
            }

        },
        blurProceso() {
            this.computedForm.refs.proceso.validate();
        },

        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosObjetivo.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboProcesos = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        // listarEmpleados() {
        //     let me = this;
        //     axios.defaults.headers.common[
        //         "Authorization"
        //     ] = `Bearer ${localStorage.token}`;
        //     axios
        //         .get(
        //             CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados", {
        //                 params: {
        //                     idCliente: me.datosObjetivo.idCliente,
        //                 },
        //             }
        //         )
        //         .then(function (response) {
        //             me.comboEmpleados = response.data
        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal!')
        //         });
        // },
        obtenerObjetivo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-objetivo", {
                        params: {
                            idCliente: me.datosObjetivo.idCliente,
                            idObjetivo: me.$route.params.id,
                        },
                    }
                )
                .then(function (response) {
                    me.datosObjetivo.idObjetivo = response.data[0].idObjetivo;
                    me.datosObjetivo.proceso = response.data[0].idProceso;
                    me.datosObjetivo.definicionObjetivo = response.data[0].definicion;
                    me.datosObjetivo.valor = response.data[0].valorReal;
                    me.datosObjetivo.fechaInicio = response.data[0].fechaInicio;
                    me.datosObjetivo.fechaLimite = response.data[0].fechaLimite;
                    me.datosObjetivo.valorPartida1 = response.data[0].valorPartidax;
                    me.datosObjetivo.valorPartida2 = response.data[0].valorPartiday;
                    me.datosObjetivo.condicion = response.data[0].condicion;
                    me.datosObjetivo.tolerancia = response.data[0].tolerancia;
                    me.datosObjetivo.valorEsperado1 = response.data[0].valorEsperadox;
                    me.datosObjetivo.valorEsperado2 = response.data[0].valorEsperadoy;
                    me.datosObjetivo.conseguido = response.data[0].conseguido;

                    me.obtenerObjetivoMetas();
                    me.obtenerObjetivoSeguimientos();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!!');
                    me.$router.push({
                        name: 'Objetivos'
                    });
                });
        },
        obtenerObjetivoMetas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-objetivo-metas", {
                        params: {
                            idCliente: me.datosObjetivo.idCliente,
                            idObjetivo: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {
                    me.datosObjetivo.listaMetasHitos = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        obtenerObjetivoSeguimientos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/obtener-objetivo-seguimientos", {
                        params: {
                            idCliente: me.datosObjetivo.idCliente,
                            idObjetivo: me.$route.params.id
                        },
                    }
                )
                .then(function (response) {
                    me.datosObjetivo.listaSeguimientos = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosObjetivo.idCliente = user.uidClient;
            this.listarProcesos();
            //this.listarEmpleados();
            this.listarPuestosTrabajo();
            if (this.$route.params.id) {
                this.obtenerObjetivo();

            }
        }
    }

}
</script>
